import superagentPromise from 'superagent-promise';
import _superagent from 'superagent';
import CommonStore from './stores/CommonStore';

const superagent = superagentPromise(_superagent, global.Promise);

const API_ROOT = `https://form-admin-api.lovd.co.kr/api`;

const handleErrors = err => {
    if (err && err.response && err.response.status === 401) {
        console.log(err);
    }

    return err;
};

const responseBody = res => res.body ?? res.text;

const tokenPlugin = req => {
    if (CommonStore.token) {
        req.set('Authorization', `Bearer ${CommonStore.token}`);
    }
};

const requests = {
    del: url =>
        superagent
            .del(`${API_ROOT}${url}`)
            .use(tokenPlugin)
            .end(handleErrors)
            .then(responseBody),
    get: url =>
        superagent
            .get(`${API_ROOT}${url}`)
            .use(tokenPlugin)
            .end(handleErrors)
            .then(responseBody),
    put: (url, body) =>
        superagent
            .put(`${API_ROOT}${url}`, body)
            .use(tokenPlugin)
            .end(handleErrors)
            .then(responseBody),
    post: (url, body) =>
        superagent
            .post(`${API_ROOT}${url}`, body)
            .use(tokenPlugin)
            .end(handleErrors)
            .then(responseBody),
};

const Common = {
    getRedate: () =>
        requests.get(`/switch/redate`),
    getYonja: () =>
        requests.get(`/switch/yonja`),
    chagneRedate: (checked) =>
        requests.put(`/switch/redate`, checked),
    chagneYonja: (checked) =>
        requests.put(`/switch/yonja`, checked),
};

export default {
    Common,
};
