import React, { useEffect, useState } from 'react';
import "./assets/css/reset.css"
import { Typography, Switch, Divider } from 'antd';
import 'antd/dist/antd.css'; 
import styled from 'styled-components';
import agent from './agent';

const App = () => {
    const [redateState, setRedateState] = useState(false);
    const [yonzaState, setYonzaState] = useState(false);
    const { Title, Paragraph } = Typography;

    useEffect(() => {
        agent.Common.getRedate()
            .then(data => setRedateState(data));

        agent.Common.getYonja()
            .then(data => setYonzaState(data));
    },[]);

    function onChangeRedate(checked) {
        
        const content = {
            active: checked
        };

        try {
            agent.Common.chagneRedate(content)
                .then((data) => {
                    setRedateState(checked);
                });
        } catch (error) {
            console.error(error);
        }
    };

    function onChangeYonja(checked) {
        setYonzaState(checked);
        const content = {
            active: checked
        };

        try {
            agent.Common.chagneYonja(content)
                .then((data) => {
                    setYonzaState(checked)
                });
        } catch (error) {
            console.error(error);
        }
    };



    return (
        <Wrap>
            <Paragraph type="danger">
                리데 혹은 연자에 에러가 발생했다면, 아래 스위칭을 이용하세요,
            </Paragraph>

            <div className="boxes">
                <Title level={2} type="success">리데이트</Title>
                <Switch checked={redateState} onChange={onChangeRedate} />
                
                <Title level={5}>{redateState ? "ON" : "OFF"}</Title>

                <Divider />

                <Title level={2} type="warning">연자</Title>
                <Switch checked={yonzaState} onChange={onChangeYonja} />

                <Title level={5}>{yonzaState ? "ON" : "OFF"}</Title>

            </div>
        </Wrap>
    );
};


const Wrap = styled.div`
    text-align: center;
    margin: 0 auto;

    .boxes {
        width: 250px;
        margin: 0 auto;
    }
`;


export default App;
